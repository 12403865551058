import { template as template_21f9f3bffcd94d65b05e30203fb64003 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { CleverUiModal } from 'district-ui-client/components/clever-ui/modal';
import { CleverUiConfirmDialog } from 'district-ui-client/components/clever-ui/confirm-dialog/component';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import { not } from 'ember-truth-helpers';
interface Signature {
    Args: {
        isShowingModal?: boolean;
        title: string;
        positiveAction: () => void;
        positiveButtonText: string;
        negativeAction: () => void;
        confirmLabel: string;
    };
    Blocks: {
        default: [];
    };
}
export class ConfirmWithCheckbox extends Component<Signature> {
    @tracked
    positiveButtonEnabled = false;
    toggleConfirm = ()=>{
        this.positiveButtonEnabled = !this.positiveButtonEnabled;
    };
    static{
        template_21f9f3bffcd94d65b05e30203fb64003(`
    <CleverUiModal @isShowingModal={{@isShowingModal}} @onClose={{@negativeAction}} @title={{@title}} @type="warning">
      <CleverUiConfirmDialog
        @positiveAction={{@positiveAction}}
        @positiveButtonText={{@positiveButtonText}}
        @positiveButtonDisabled={{not this.positiveButtonEnabled}}
        @negativeAction={{@negativeAction}}
        @negativeButtonText={{t "cancel"}}
      >
        {{yield}}
        <div class="mt-1 text-center">
          <label class="align-middle">
            <input
              data-test-modal-confirm-checkbox
              class="mr-4 cursor-pointer align-middle"
              type="checkbox"
              checked={{this.positiveButtonEnabled}}
              {{on "input" this.toggleConfirm}}
            />
            <span class="align-middle">{{@confirmLabel}}</span>
          </label>
        </div>
      </CleverUiConfirmDialog>
    </CleverUiModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ConfirmWithCheckbox;
