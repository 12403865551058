import { template as template_7878de2c144145bc894a7288f98eafc2 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const ModalDefaultFooter: TOC<Signature> = template_7878de2c144145bc894a7288f98eafc2(`
  <div ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalDefaultFooter;
