import { template as template_dac589c8d2d6441bb845af5e689dcca6 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { fn, array } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { isPresent } from '@ember/utils';
import type { SafeString } from '@ember/template';
import { t, type IntlService } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
import orderBy from 'lodash/orderBy';
import type ActiveRouteService from 'district-ui-client/services/active-route';
import type ManageSchoolsIndexRoute from 'district-ui-client/subscription-type/manage/schools/index/route';
import { formatValue } from 'district-ui-client/utils/format-value';
import { OverCapacityAlert } from 'district-ui-client/components/over-capacity-alert';
import { Tooltip } from 'district-ui-client/components/tooltip';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { TableContainer, Table, TRHead, TBody, TH, TD } from 'district-ui-client/components/table';
import { linkClassNames } from 'district-ui-client/components/themed-link';
import { Panel, PanelHeader, PanelTitle, PanelBody } from 'district-ui-client/components/section';
import { PageTitle } from 'district-ui-client/components/page-title';
import { RolloverAlert } from 'district-ui-client/components/rollover-alert';
import { InputSearch } from 'district-ui-client/components/input-search';
import colspanMax from 'district-ui-client/modifiers/colspan-max';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { hash } from '@ember/helper';
interface SchoolRow {
    schoolId: string;
    schoolName: string;
    schoolCode: string;
    schoolTown: string | SafeString;
    subcoName: string | SafeString;
    subcoNameReversed: string | SafeString;
    subExpiryDate: Date;
    subExpiryFormatted: string;
    subCapacity: number;
    subCapacityFormatted: string;
    subIsOverCapacity: boolean;
    teacherCount: number;
    studentCount: number;
}
type SortKey = keyof SchoolRow;
type SortDir = 'asc' | 'desc';
interface Signature {
    Args: {
        model: ModelFor<ManageSchoolsIndexRoute>;
    };
}
class ManageSchoolsIndexTemplate extends Component<Signature> {
    @service
    activeRoute: ActiveRouteService;
    @service
    intl: IntlService;
    get subscriptionType() {
        return this.activeRoute.subscriptionType;
    }
    @tracked
    search = '';
    @tracked
    sorts: `${SortKey}:${SortDir}`[] = [
        'schoolName:asc'
    ];
    get sortKey() {
        return this.sorts[0].split(':')[0] as SortKey;
    }
    get sortDirection() {
        return this.sorts[0].split(':')[1] as SortDir;
    }
    get sortIcon(): IconName {
        switch(this.sortDirection){
            case 'asc':
                return 'sort-up';
            case 'desc':
                return 'sort-down';
            default:
                return 'sort';
        }
    }
    get sortedRows() {
        const properties = this.sorts.map((sort)=>sort.split(':')[0]);
        const directions = this.sorts.map((sort)=>sort.split(':')[1]);
        assert('unknown sort direction', directions.every((d)=>d === 'asc' || d === 'desc'));
        return orderBy(this.rows, properties, directions);
    }
    get rows(): SchoolRow[] {
        const { subscriptionType } = this;
        return this.args.model.schools.map((school)=>{
            const utilisation = school.utilisationFor(subscriptionType);
            if (!utilisation) return null // manage schools should only be active schools (schools with utilisation record)
            ;
            const subcoRecord = school.subscriptionCoordinators?.find((subco)=>subco.isForSubscriptionType(subscriptionType));
            const schoolStat = this.args.model.schoolStats.find((schoolStat)=>schoolStat.id === school.id);
            const teacherCount = schoolStat?.teacherCountFor(subscriptionType) ?? 0;
            const studentCount = schoolStat?.studentCountFor(subscriptionType) ?? 0;
            return {
                schoolId: school.id,
                schoolName: school.name,
                schoolCode: school.schoolCode,
                schoolTown: formatValue(school.contactDetail?.town),
                subcoName: formatValue(subcoRecord?.teacher?.fullName, {
                    replacement: this.intl.t('na')
                }),
                subcoNameReversed: formatValue(subcoRecord?.teacher?.fullNameReversed, {
                    replacement: this.intl.t('na')
                }),
                subExpiryDate: utilisation.endDate,
                subExpiryFormatted: this.intl.formatDate(utilisation.endDate, {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                }),
                subCapacity: utilisation.licenceCount,
                subCapacityFormatted: utilisation.formattedCapacity,
                subIsOverCapacity: utilisation.isOverCapacity,
                teacherCount,
                studentCount
            };
        }).filter(isPresent);
    }
    get filteredContent() {
        const lowerCaseSearch = this.search.toLowerCase();
        return this.sortedRows.filter((row)=>[
                row.schoolName,
                row.schoolCode,
                row.schoolTown,
                row.subcoName,
                row.subcoNameReversed
            ].join(' ').toLowerCase().includes(lowerCaseSearch));
    }
    setSearch = (value: string)=>{
        this.search = value;
    };
    /**
   * Updates the sort direction and properties for schools
   */ sortBy = (property: SortKey)=>{
        // Only supports 1 column, but can be extended.
        const [sortProperty, sortDirection] = this.sorts[0].split(':') as [SortKey, SortDir];
        if (sortProperty === property) {
            this.sorts = [
                `${property}:${sortDirection === 'asc' ? 'desc' : 'asc'}`
            ];
        } else {
            this.sorts = [
                `${property}:asc`
            ];
        }
    };
    static{
        template_dac589c8d2d6441bb845af5e689dcca6(`
    <PageTitle>{{t "subscriptionType.manageSchools.index.manageSchools"}}</PageTitle>

    <div class="mt-8 space-y-6">
      <RolloverAlert />
      <OverCapacityAlert @schools={{@model.schools}} @subscriptionType={{this.subscriptionType}} />

      <Panel>
        <PanelHeader>
          <div class="flex items-center justify-between gap-4">
            <PanelTitle>{{t "schools"}}</PanelTitle>
            <div>
              <InputSearch
                aria-label={{t "searchPlaceholder.schools"}}
                placeholder={{t "searchPlaceholder.schools"}}
                @value={{this.search}}
                @updateValue={{this.setSearch}}
              />
            </div>
          </div>
        </PanelHeader>
        <PanelBody @tight={{true}}>
          <TableContainer>
            <Table @minWidth="min-w-[68rem]">
              <thead>
                <TRHead>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "schoolName")}} data-test-school-name-col>
                    {{t "subscriptionType.manageSchools.index.name"}}
                    <FaIcon @icon={{if (eq this.sortKey "schoolName") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "schoolTown")}}>
                    {{t "subscriptionType.manageSchools.index.city"}}
                    <FaIcon @icon={{if (eq this.sortKey "schoolTown") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "schoolCode")}} class="w-32">
                    {{t "subscriptionType.manageSchools.index.code"}}
                    <FaIcon @icon={{if (eq this.sortKey "schoolCode") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "subcoNameReversed")}} class="w-48">
                    {{t "subscriptionType.manageSchools.index.subscriptionCoordinator" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "subcoNameReversed") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "subExpiryDate")}} class="w-32">
                    {{t "subscriptionType.manageSchools.index.subscriptionExpiry" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "subExpiryDate") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "subCapacity")}} class="w-32">
                    {{t "subscriptionType.manageSchools.index.subscriptionCapacity" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "subCapacity") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "teacherCount")}} class="w-36">
                    {{t "subscriptionType.manageSchools.index.numberOfTeachers" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "teacherCount") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "studentCount")}} class="w-36">
                    {{t "subscriptionType.manageSchools.index.numberOfStudents" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "studentCount") this.sortIcon "sort"}} />
                  </TH>
                </TRHead>
              </thead>
              <TBody>
                {{#each this.filteredContent as |row index|}}
                  <tr data-test-school-row={{index}}>
                    <TD data-test-school-name>
                      <LinkTo
                        class={{linkClassNames}}
                        @route="subscription-type.manage.schools.edit"
                        @models={{array this.subscriptionType row.schoolId}}
                      >
                        {{row.schoolName}}
                      </LinkTo>
                    </TD>
                    <TD data-test-school-city>{{row.schoolTown}}</TD>
                    <TD data-test-school-code>{{row.schoolCode}}</TD>
                    <TD data-test-school-subco-name>{{row.subcoNameReversed}}</TD>
                    <TD data-test-school-expiry-date>{{row.subExpiryFormatted}}</TD>
                    <TD
                      data-test-school-subscription-capacity
                      @textColor={{if row.subIsOverCapacity "text-orange-400"}}
                    >
                      <span>
                        {{row.subCapacityFormatted}}
                        {{#if row.subIsOverCapacity}}
                          <FaIcon @icon="triangle-exclamation" />
                          <Tooltip @text={{t "subscriptionType.manageSchools.index.overCapacityTooltip"}} />
                        {{/if}}
                      </span>
                    </TD>
                    <TD data-test-school-teachers-link>
                      <LinkTo
                        class={{linkClassNames}}
                        @route="subscription-type.manage.teachers"
                        @query={{hash schoolId=row.schoolId}}
                        data-test-manage-schools-teacher-link
                      >
                        {{t "subscriptionType.manageSchools.index.teachers" count=row.teacherCount}}
                      </LinkTo>
                    </TD>
                    <TD data-test-school-students-link>
                      <LinkTo
                        class={{linkClassNames}}
                        @route="subscription-type.manage.students"
                        {{! clear out any other QPs that might have been previously set }}
                        @query={{hash
                          schoolId=row.schoolId
                          teacherId=""
                          schoolClassId=""
                          studentFirstName=""
                          studentLastName=""
                        }}
                      >
                        {{t "subscriptionType.manageSchools.index.students" count=row.studentCount}}
                      </LinkTo>
                    </TD>
                  </tr>
                {{else}}
                  <tr>
                    <TD {{colspanMax}}>
                      {{t "noResults"}}
                    </TD>
                  </tr>
                {{/each}}
              </TBody>
            </Table>
          </TableContainer>
        </PanelBody>
      </Panel>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ManageSchoolsIndexTemplate);
