import { template as template_e622e978c6144db9a728ff60148dcb1c } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type { IntlService } from 'ember-intl';
import type SchoolRolloverService from 'district-ui-client/services/school-rollover';
import { ModalDefault } from 'district-ui-client/components/modal-default';
import { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { UiButton } from 'district-ui-client/components/ui-button';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
interface Args {
    subscriptionType: SubscriptionType;
    onClose: () => unknown;
}
interface Signature {
    Args: Args;
}
export class RolloverModal extends Component<Signature> {
    @service
    intl: IntlService;
    @service
    schoolRollover: SchoolRolloverService;
    get schoolsInRollover() {
        return this.schoolRollover.schoolsInRollover;
    }
    get schoolNamesAndSubscriptionTypes() {
        return this.schoolsInRollover.map((school)=>({
                name: school.name,
                subscriptionType: school.schoolRollovers?.map((rollover)=>this.intl.t(rollover.subscriptionType)).join(', ')
            }));
    }
    get subscriptionTypeClasses() {
        const classes = {
            [SubscriptionType.Reading]: 'bg-oceany-blue-300',
            [SubscriptionType.Maths]: 'bg-ms-green-325',
            [SubscriptionType.Writing]: ''
        };
        return classes[this.args.subscriptionType];
    }
    static{
        template_e622e978c6144db9a728ff60148dcb1c(`
    {{#if this.schoolsInRollover}}
      <ModalDefault data-test-rollover-modal @onClose={{@onClose}} as |modal|>
        <modal.header
          class="text-white [&_button]:font-normal [&_button]:text-white [&_button]:opacity-100
            {{this.subscriptionTypeClasses}}"
        >
          <span class="text-2xl font-semibold">{{t "rollovers.modalTitle"}}</span>
        </modal.header>
        <modal.body class="max-h-1/2-screen p-7">
          <p class="text-left text-base leading-relaxed">{{t "rollovers.alert"}}</p>
          <ul class="list-disc px-5 pt-1 text-left font-bold leading-relaxed">
            {{#each this.schoolNamesAndSubscriptionTypes as |school|}}
              <li>
                <span>{{school.name}}</span>
                <span>({{school.subscriptionType}})</span>
              </li>
            {{/each}}
          </ul>
        </modal.body>
        <modal.footer class="border-dusty-black-100 border-t p-1 text-right">
          <UiButton
            data-test-confirm-btn
            class="{{@subscriptionType}} regular active w-40 min-w-36 p-1"
            {{on "click" @onClose}}
          >{{t "rollovers.confirmBtn"}}</UiButton>
        </modal.footer>
      </ModalDefault>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RolloverModal;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rollover::Modal': typeof RolloverModal;
    }
}
