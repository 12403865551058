import { template as template_598054ec6a514ac8b3a5c2774abe5d7d } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { ConfirmWithCheckbox } from 'district-ui-client/components/clever-ui/modals/confirm-with-checkbox';
import { t } from 'ember-intl';
import type Store from '@ember-data/store';
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher';
import type GravityTeacher from 'district-ui-client/models/clever/teacher';
/**
 * Given Clever ID or SIS ID already in use, user can re-assign the in-use
 * Clever ID or SIS ID and delete the teacher account for other teacher.
 */ interface Signature {
    Args: {
        cleverTeacher: CleverTeacher;
        isShowingModal?: boolean;
        positiveAction: () => void;
        negativeAction: () => void;
    };
}
export class StaleAccountErrorTeacherMatcherModal extends Component<Signature> {
    @service
    store: Store;
    get otherTeacherId() {
        return this.args.cleverTeacher.matchError?.['other-teacher-id'];
    }
    get otherTeacher(): GravityTeacher | null {
        if (!this.otherTeacherId) return null;
        return this.store.peekRecord('clever/teacher', this.otherTeacherId);
    }
    static{
        template_598054ec6a514ac8b3a5c2774abe5d7d(`
    <ConfirmWithCheckbox
      @isShowingModal={{@isShowingModal}}
      @title={{t "clever.teacherMatcher.errors.staleAccountError.summary"}}
      @positiveAction={{@positiveAction}}
      @positiveButtonText={{t "clever.teacherMatcher.errors.staleAccountError.positiveButtonText"}}
      @negativeAction={{@negativeAction}}
      @confirmLabel={{t
        "clever.teacherMatcher.errors.staleAccountError.confirmLabel"
        htmlSafe=true
        otherTeacherFirstName=this.otherTeacher.firstName
        otherTeacherLastName=this.otherTeacher.lastName
      }}
    >
      <p class="text-base">
        {{t
          "clever.teacherMatcher.errors.staleAccountError.explanation"
          htmlSafe=true
          otherTeacherFirstName=this.otherTeacher.firstName
          otherTeacherLastName=this.otherTeacher.lastName
        }}
      </p>
      <p class="mb-0 text-base">
        {{t
          "clever.teacherMatcher.errors.staleAccountError.solution"
          htmlSafe=true
          otherTeacherFirstName=this.otherTeacher.firstName
          otherTeacherLastName=this.otherTeacher.lastName
        }}
      </p>
    </ConfirmWithCheckbox>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default StaleAccountErrorTeacherMatcherModal;
