import { template as template_aabf5ac0fdd94e00843ee9110923b865 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { CleverUiModal } from 'district-ui-client/components/clever-ui/modal';
import { CleverUiConfirmDialog } from 'district-ui-client/components/clever-ui/confirm-dialog/component';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
interface Signature {
    Args: {
        selectedCount: number;
        onClose: () => void;
        onSubmit: () => void;
    };
}
export class CleverModalsResetConfirm extends Component<Signature> {
    @tracked
    isResetChecked = false;
    setResetChecked = (value: boolean)=>{
        this.isResetChecked = value;
    };
    static{
        template_aabf5ac0fdd94e00843ee9110923b865(`
    <CleverUiModal
      @isShowingModal={{true}}
      @onClose={{@onClose}}
      @title={{t "components.cleverUi.modals.resetConfirmModal.title"}}
      @type="warning"
    >
      <CleverUiConfirmDialog
        data-test-reset-modal
        @negativeAction={{@onClose}}
        @positiveAction={{@onSubmit}}
        @negativeButtonText={{t "components.cleverUi.modals.resetConfirmModal.cancel"}}
        @positiveButtonText={{t "components.cleverUi.modals.resetConfirmModal.reset"}}
        @positiveButtonDisabled={{not this.isResetChecked}}
        @positiveButtonAppearance="orange"
      >
        <div>
          <p class="text-base">{{t "components.cleverUi.modals.resetConfirmModal.body"}}</p>
          <p class="mt-3 text-base">
            {{t "components.cleverUi.modals.resetConfirmModal.schoolCount" count=@selectedCount htmlSafe=true}}
          </p>
          <p class="mt-3 text-base">
            {{t "components.cleverUi.modals.resetConfirmModal.areYouSure"}}
          </p>
          <label class="mt-5 inline-flex items-center" data-test-reset-confirm-checkbox>
            <input
              class="mr-4"
              type="checkbox"
              checked={{this.isResetChecked}}
              {{on "click" (fn this.setResetChecked (not this.isResetChecked))}}
            />
            {{t "components.cleverUi.modals.resetConfirmModal.label"}}
          </label>
          <p class="mb-0 mt-5 text-base font-bold">
            <FaIcon @icon="triangle-exclamation" @size="lg" class="text-watermelony-red-300 mr-0.5" />
            {{t "components.cleverUi.modals.resetConfirmModal.warning"}}
          </p>
        </div>
      </CleverUiConfirmDialog>
    </CleverUiModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CleverModalsResetConfirm;
