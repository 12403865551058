import { template as template_7ef414158e244994a6638eb837921601 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DashboardControls from 'district-ui-client/components/reporting/dashboard-controls';
import { ReportingDistrictHeader } from 'district-ui-client/components/reporting/district-header';
import { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import type MathsDashboardTableRoute from 'district-ui-client/reporting/ui-scope/maths/dashboard/table/route';
import type EmailAllStudentsCsvService from 'district-ui-client/services/email-all-students-csv';
import type SessionService from 'district-ui-client/services/session';
import { statefulSort } from 'district-ui-client/utils/giraffe/stateful-sort';
import DataTableOperations from '@blakeelearning/data-tables/components/data-table-operations/index';
import { t } from 'ember-intl';
import { eq, gt } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { on } from '@ember/modifier';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { thousands } from 'district-ui-client/helpers/thousands';
import formatValue from 'district-ui-client/helpers/format-value';
import formatHumanizedDuration from 'district-ui-client/helpers/format-humanized-duration';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<MathsDashboardTableRoute>;
    };
}
export class MathsDashboardTableTemplate extends Component<Signature> {
    @service
    emailAllStudentsCsv: EmailAllStudentsCsvService;
    @service
    session: SessionService;
    @tracked
    currentSortColumn = null;
    @tracked
    currentSortIcon: IconName = 'sort';
    get isSummaryDataPresent() {
        return isPresent(this.args.model.summary) && isPresent(this.args.model.summary?.summary.schools);
    }
    get rows() {
        const schools = this.args.model.scopedSchools;
        const summaryStats = this.args.model.summary?.summary?.schools ?? [];
        return schools.map((school)=>{
            const statsForSchool = summaryStats.find((stat)=>String(stat.school_id) === school.id);
            const schoolWithStats = {
                school_name: school.name,
                contributing_students: school.utilisationFor(SubscriptionType.Maths)?.studentCount ?? 0,
                ...statsForSchool
            };
            return schoolWithStats;
        });
    }
    get summaryRow() {
        return {
            ...(this.args.model.summary?.summary.district || {}),
            contributing_students: this.args.model.scopedSchools.reduce((total, school)=>total + (school.utilisationFor(SubscriptionType.Maths)?.studentCount ?? 0), 0)
        };
    }
    statefulSort = (...args: Parameters<typeof statefulSort>)=>{
        return statefulSort.apply(this, args);
    };
    emailAllStudentsCSV = ()=>{
        const districtId = this.session.currentDistrict.id;
        const product = this.args.model.product;
        const period = this.args.model.period;
        void this.emailAllStudentsCsv.emailAllStudentsCSV(districtId, product, period);
    };
    static{
        template_7ef414158e244994a6638eb837921601(`
    <ReportingDistrictHeader />
    <DashboardControls
      class="my-3"
      @viewMode="table"
      @isDataPresent={{this.isSummaryDataPresent}}
      @emailAllStudentsCSV={{this.emailAllStudentsCSV}}
    />
    <div class="overflow-x-auto">
      <DataTableOperations @data={{this.rows}} as |transformedRows tableAction|>
        <table data-test-dash-table class="disco-table w-full bg-white text-center text-sm" tabindex="0" exportable>
          <thead class="divide-y divide-white">
            <tr>
              <th>{{t "reporting.uiScope.maths.dashboard.table.school"}}</th>
              <th>{{t "reporting.uiScope.maths.dashboard.table.activeStudents"}}</th>
              <th colspan="3">{{t "reporting.uiScope.maths.dashboard.table.totalCompleted AvgPerStud"}}</th>
              {{!<th>Avg course progress</th>}}
              <th colspan="3">{{t "reporting.uiScope.maths.dashboard.table.certificates"}}</th>
              <th>{{t "reporting.uiScope.maths.dashboard.table.usage"}}</th>
              <th>
                {{t "reporting.district.dashboard.table.totalTimeInProgram"}}
              </th>
            </tr>
            <tr>
              <th
                class={{if (eq this.currentSortColumn "school_name") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByValue" "school_name")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon @icon={{if (eq this.currentSortColumn "school_name") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "contributing_students") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "contributing_students")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon
                    @icon={{if (eq this.currentSortColumn "contributing_students") this.currentSortIcon "sort"}}
                  />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "lessons_completed") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "lessons_completed")}}
              >
                <div class="flex items-center justify-between gap-2">
                  {{t "maths"}}
                  <FaIcon @icon={{if (eq this.currentSortColumn "lessons_completed") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "driving_tests_completed") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "driving_tests_completed")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.maths.dashboard.table.drivingTests"
                  }}<FaIcon
                    @icon={{if (eq this.currentSortColumn "driving_tests_completed") this.currentSortIcon "sort"}}
                  />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "assessments") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "assessments")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.maths.dashboard.table.quizzesCompleted"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "assessments") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "awards_earned_gold") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "awards_earned_gold")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.maths.dashboard.table.gold"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "awards_earned_gold") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "awards_earned_silver") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "awards_earned_silver")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.maths.dashboard.table.silver"
                  }}<FaIcon
                    @icon={{if (eq this.currentSortColumn "awards_earned_silver") this.currentSortIcon "sort"}}
                  />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "awards_earned_bronze") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "awards_earned_bronze")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.maths.dashboard.table.bronze"
                  }}<FaIcon
                    @icon={{if (eq this.currentSortColumn "awards_earned_bronze") this.currentSortIcon "sort"}}
                  />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "total_usage") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "total_usage")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon @icon={{if (eq this.currentSortColumn "total_usage") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "total_time") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "total_time")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon @icon={{if (eq this.currentSortColumn "total_time") this.currentSortIcon "sort"}} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {{#if (gt @model.scopedSchools.length 1)}}
              <tr data-fixed class="font-semibold">
                <td>{{t "reporting.uiScope.maths.dashboard.table.allSchools"}}</td>
                <td>
                  {{thousands this.summaryRow.contributing_students}}
                </td>
                <td>
                  {{thousands this.summaryRow.lessons_completed}}
                  |
                  {{formatValue this.summaryRow.average_lessons_completed_per_student}}
                </td>
                <td>
                  {{thousands this.summaryRow.driving_tests_completed}}
                  |
                  {{formatValue this.summaryRow.average_driving_tests_completed_per_student}}
                </td>
                <td>
                  {{thousands this.summaryRow.assessments}}
                  |
                  {{formatValue this.summaryRow.average_assessments_per_student}}
                </td>
                {{!--<td>{{thousands summaryRow.average_course_progress}}</td>--}}
                <td>
                  {{thousands this.summaryRow.awards_earned_gold}}
                </td>
                <td>
                  {{thousands this.summaryRow.awards_earned_silver}}
                </td>
                <td>
                  {{thousands this.summaryRow.awards_earned_bronze}}
                </td>
                <td>
                  {{thousands this.summaryRow.total_usage}}
                </td>
                <td>
                  {{formatHumanizedDuration this.summaryRow.total_time "-"}}
                </td>
              </tr>
            {{/if}}
            {{#each transformedRows as |row|}}
              <tr>
                <td>
                  {{row.school_name}}
                </td>
                <td>
                  {{thousands row.contributing_students}}
                </td>
                <td>
                  {{thousands row.lessons_completed}}
                  |
                  {{formatValue row.average_lessons_completed_per_student}}
                </td>
                <td>
                  {{thousands row.driving_tests_completed}}
                  |
                  {{formatValue row.average_driving_tests_completed_per_student}}
                </td>
                <td>
                  {{thousands row.assessments}}
                  |
                  {{formatValue row.average_assessments_per_student}}
                </td>
                <td>
                  {{thousands row.awards_earned_gold}}
                </td>
                <td>
                  {{thousands row.awards_earned_silver}}
                </td>
                <td>
                  {{thousands row.awards_earned_bronze}}
                </td>
                <td>
                  {{thousands row.total_usage}}
                </td>
                <td>
                  {{formatHumanizedDuration row.total_time "-"}}
                </td>
              </tr>
            {{/each}}
          </tbody>
        </table>
      </DataTableOperations>
    </div>

    <p class="bg-white p-1 font-bold">
      {{t "reporting.uiScope.maths.dashboard.table.UsageTotalCountOfMathemat"}}
    </p>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(MathsDashboardTableTemplate);
