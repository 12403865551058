import { template as template_af8843d7bc2747ff9d7036c595e1d793 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { capitalize } from '@ember/string';
import blakeColors from '@blakeelearning/blake-colours/colours';
import { t } from 'ember-intl';
import SeriesBar from 'district-ui-client/components/primitives/series-bar/component';
import type { SeriesBarOptions } from 'highcharts';
type StadiumEvent = 'spelling' | 'grammar' | 'vocabulary' | 'usage';
export interface StadiumEventsData {
    stadium_events: {
        event_type: StadiumEvent;
        count: number;
    }[];
}
interface Signature {
    Args: {
        data: StadiumEventsData;
    };
}
const eventTypes = [
    'spelling',
    'grammar',
    'vocabulary',
    'usage'
] as const;
export class StadiumEventsDashboardChart extends Component<Signature> {
    get stadiumEvents() {
        return this.args.data.stadium_events;
    }
    get categories() {
        return eventTypes.map((eventType)=>capitalize(eventType));
    }
    get rows(): SeriesBarOptions[] {
        const { stadiumEvents } = this;
        const data = eventTypes.map((eventType)=>{
            return stadiumEvents.find((event)=>event.event_type === eventType)?.count ?? 0;
        });
        return [
            {
                data,
                type: 'bar'
            }
        ];
    }
    get colours() {
        return [
            blakeColors.oceanyBlue300,
            blakeColors.oceanyBlue400,
            blakeColors.juicyOrange300,
            blakeColors.purplyPink300
        ];
    }
    static{
        template_af8843d7bc2747ff9d7036c595e1d793(`
    <div class="flex h-full flex-col" data-test-stadium-events>
      <div data-test-dashboard-subtitle class="flex justify-between gap-4 text-xs">
        <span>
          {{t "reporting.charts.dashboard.stadiumEvents.overallEventsCompleted"}}
        </span>
      </div>
      <SeriesBar
        @data={{this.rows}}
        @categories={{this.categories}}
        @colours={{this.colours}}
        @colorByPoint={{true}}
        @barPadding={{0}}
        @groupPadding={{0.15}}
        @yAxisLabel="Number of plays"
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default StadiumEventsDashboardChart;
