import { template as template_3a9fa49e5dd4448795e82d49913129c7 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type ReCourseProgressTotalsRoute from 'district-ui-client/reporting/ui-scope/re/course-progress/totals/route';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { not } from 'ember-truth-helpers';
import { ChartsCourseProgressTotals } from 'district-ui-client/components/charts/course-progress/totals/component';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
interface Signature {
    model: ModelFor<ReCourseProgressTotalsRoute>;
}
export const ReportingReCourseProgressTotalsRouteTemplate: TOC<Signature> = template_3a9fa49e5dd4448795e82d49913129c7(`
  <DetailedPageWrapper @empty={{not @model.courseProgressTotals}}>
    {{#each @model.courseProgressTotals.course_progress_totals as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsCourseProgressTotals
          class="h-full"
          @data={{gradeData}}
          @precinct={{@model.precinct}}
          @product={{@model.product}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.courseProgressTotals"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReportingReCourseProgressTotalsRouteTemplate);
