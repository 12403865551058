import { template as template_c1a7e447dcd14688b35534867846a3c6 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { on } from '@ember/modifier';
import { ThemedButton, ThemedLinkTo, type ButtonStyle } from 'district-ui-client/components/themed-button';
interface Signature {
    Element: HTMLElement;
    Args: {
        isDisabled?: boolean;
        isActive?: boolean;
        onClick?: () => unknown;
        query?: Record<string, unknown>;
        route?: string;
        buttonStyle: ButtonStyle;
    };
    Blocks: {
        default: [];
    };
}
export const BasePageSelectorTraverser: TOC<Signature> = template_c1a7e447dcd14688b35534867846a3c6(`
  {{#if @isDisabled}}
    <ThemedButton @style={{@buttonStyle}} @disabled={{true}} ...attributes>
      {{yield}}
    </ThemedButton>
  {{else if @onClick}}
    {{! Action based Pagination (without Query Params) }}
    <ThemedButton @style={{@buttonStyle}} class={{if @isActive "active"}} {{on "click" @onClick}} ...attributes>
      {{yield}}
    </ThemedButton>
  {{else}}
    {{! Query Param based Pagination }}
    <ThemedLinkTo
      @style={{@buttonStyle}}
      {{! LinkTo normally applies activeClass when it is determined to be the currentRoute. That is difficult to test as it
    relies on routing being setup in order to determine its active state. Instead, we can do the functional equivalent
    and apply the active class ourselves when currentPage === pageNumber. }}
      class="{{if @isActive 'active'}}"
      @activeClass=""
      @route={{@route}}
      @query={{@query}}
      {{! In theory, this should work just fine without current-when (and it seems to in most cases). However, when
    transitioning between some routes, particularly to one with an async model hook, the LinkTo "isActive" check
    fails with a shouldSupersede error. Figuring this is another ember routing / query param bug. We can skip its
    internal isActive check by specifying current-when arg}}
      @current-when={{if @isActive true false}}
      ...attributes
    >
      {{yield}}
    </ThemedLinkTo>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
