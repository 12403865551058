import { template as template_664d0b533dd74ca3831889f4f16e927d } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { SubscriptionType } from 'district-ui-client/domain/subscription-type';
interface Signature {
    Args: {
        subscriptionType?: SubscriptionType;
        isClever?: boolean;
        title: string;
        schoolName: string;
        schoolLocation: string;
    };
    Element: HTMLDivElement;
}
export class CleverAutomatchBox extends Component<Signature> {
    get titleBgClass() {
        if (this.args.isClever) {
            // Specific colour for clever
            return 'bg-[#4275f5]';
        } else if (this.args.subscriptionType === SubscriptionType.Reading) {
            return 'bg-oceany-blue-300';
        } else if (this.args.subscriptionType === SubscriptionType.Maths) {
            return 'bg-ms-green-300';
        } else {
            return '';
        }
    }
    static{
        template_664d0b533dd74ca3831889f4f16e927d(`
    <div data-test-automatch-box class="border-dusty-black-100 overflow-hidden rounded-md border" ...attributes>
      <div class="{{this.titleBgClass}} p-2 text-center font-semibold text-white">
        {{@title}}
      </div>
      <div class="space-y-2 px-5 py-7 text-center">
        <h2>{{@schoolName}}</h2>
        <p>{{@schoolLocation}}</p>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CleverAutomatchBox;
