import { template as template_c8e52312bc1e4b038ab83ef647508769 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import { ThemedLinkTo } from 'district-ui-client/components/themed-button';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
interface Signature {
    Element: HTMLDivElement;
}
/**
 * Intended to be displayed when an unexpected errors is encountered.
 *
 * If you know the error that is likely to occur, you should create or use another component with more detail instead of
 * using this one - or better yet, handle the error.
 */ export const ErrorsUnexpected: TOC<Signature> = template_c8e52312bc1e4b038ab83ef647508769(`
  <div data-test-errors-unexpected class="text-center" ...attributes>
    <FaIcon @icon="circle-exclamation" class="mb-3 h-[200px] w-[200px] text-neutral-100" />
    <div class="space-y-3">
      <h2 class="mt-0 text-xl font-medium" data-test-unexpected-title>
        {{t "components.errors.unexpectedError.title"}}
      </h2>
      <p class="text-base" data-test-unexpected-body>
        {{t "components.errors.unexpectedError.body" htmlSafe=true}}
      </p>
      <div class="p-3">
        <ThemedLinkTo data-test-errors-button-home @style="theme" @route="subscription-type.index">
          {{t "components.errors.buttonHome"}}
        </ThemedLinkTo>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
