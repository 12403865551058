import { template as template_5d79d40314514374aac19d51e5f29ca8 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { htmlSafe } from '@ember/template';
import { t } from 'ember-intl';
import { FormComponentsFormSelect } from 'district-ui-client/components/form-components/form-select';
import type { SelectOption } from 'district-ui-client/components/form-components/form-select/option';
import UiButton from 'district-ui-client/components/ui-button';
import { on } from '@ember/modifier';
import { UiButtonState } from 'district-ui-client/components/ui-button/state';
import type GravitySchool from 'district-ui-client/models/clever/school';
const optionTemplate = htmlSafe(`
  <div>
    <%-schoolName%>
  </div>
  <div class="text-sm">
    <%-schoolTown%>, <%-schoolState%>
  </div>
`);
interface SchoolOption extends SelectOption {
    schoolName: string;
    schoolTown?: string;
    schoolState?: string;
}
function schoolToOption(school: GravitySchool): SchoolOption {
    return {
        schoolName: school.name,
        schoolTown: school.contactDetail?.town,
        schoolState: school.contactDetail?.state,
        text: school.name,
        value: school.id,
        uid: school.id
    };
}
interface Signature {
    Args: {
        blakeSchools: GravitySchool[];
        isLoading?: boolean;
        matchedSchool?: GravitySchool;
        submitAction: (uid: string) => void;
        productName?: string;
    };
    Element: HTMLDivElement;
}
export class CleverMatchSchool extends Component<Signature> {
    @tracked
    _selectedOption?: SchoolOption;
    get selectedOption() {
        return this._selectedOption ?? this.defaultOption;
    }
    get componentId() {
        return guidFor(this);
    }
    // If there is a matched school provided, then we want to show the reset text
    get showResetText() {
        return Boolean(this.args.matchedSchool);
    }
    get isButtonDisabled() {
        return this.defaultOption?.uid === this.selectedOption?.uid || this.args.isLoading;
    }
    get options() {
        return this.args.blakeSchools.map(schoolToOption);
    }
    get defaultOption() {
        return this.args.matchedSchool ? schoolToOption(this.args.matchedSchool) : undefined;
    }
    resetDropdown = ()=>{
        this._selectedOption = undefined;
    };
    selectSchoolOption = (option: SchoolOption)=>{
        this._selectedOption = option;
    };
    submit = ()=>{
        if (this.selectedOption?.uid) {
            this.args.submitAction(this.selectedOption.uid);
        }
    };
    static{
        template_5d79d40314514374aac19d51e5f29ca8(`
    <div data-test-clever-match-school ...attributes>
      <div class="mb-3 flex items-baseline">
        <label for="{{this.componentId}}-field" class="flex-shrink flex-grow">
          {{#if this.showResetText}}
            {{t "clever.resetFormSelectFieldTitle"}}
          {{else}}
            {{t "clever.matchSchoolPageSelectSchoolDropdownLabel" productName=@productName}}
          {{/if}}
        </label>
        <div id="{{this.componentId}}-field" class="flex-shrink flex-grow">
          <FormComponentsFormSelect
            @defaultText={{t "clever.selectSchoolText"}}
            @value={{this.selectedOption}}
            @search={{true}}
            @options={{this.options}}
            @optionTemplate={{optionTemplate}}
            @searchPlaceholder={{t "clever.searchSchoolsText"}}
            @optionClick={{this.selectSchoolOption}}
            @resetAction={{this.resetDropdown}}
          />
        </div>
      </div>
      <p class="mb-7 text-left text-base" data-test-instructions>
        {{#if this.showResetText}}
          {{t "clever.resetFormBodyCopy"}}
        {{else}}
          {{t "clever.matchSchoolInitiateInstructions"}}
        {{/if}}
      </p>
      <div class="text-center">
        <UiButton
          data-test-submit-match-school
          class="{{if this.isButtonDisabled 'muted' 'regular'}}"
          disabled={{this.isButtonDisabled}}
          {{on "click" this.submit}}
        >
          <UiButtonState @isLoading={{@isLoading}}>
            {{t "clever.matchSchool"}}
          </UiButtonState>
        </UiButton>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CleverMatchSchool;
