import { template as template_95967eb0997b4eaf8ce39483377cbb6b } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
interface Data {
    colour: string;
    label: string;
    data: number;
}
interface Args {
    data: Data[];
    labelsBelow?: boolean;
}
interface Signature {
    Args: Args;
    Element: HTMLDivElement;
}
export class ChartLegend extends Component<Signature> {
    get categories() {
        return this.args.data.map((category)=>{
            const style = htmlSafe(`background-color: ${category.colour} !important;`) // needs to be important so shows up on print
            ;
            return {
                ...category,
                style
            };
        });
    }
    static{
        template_95967eb0997b4eaf8ce39483377cbb6b(`
    <div data-test-chart-legend class="flex flex-nowrap items-center justify-center print:!rounded-full" ...attributes>
      {{#each this.categories as |category index|}}
        <div data-test-legend-item={{index}} class="shrink grow basis-full text-center">
          <span
            data-test-legend-circle
            class="inline-block h-3 w-3 rounded-full print:rounded-full"
            style={{category.style}}
          />
          <span data-test-legend-label class="{{if @labelsBelow 'block text-xs'}} font-bold">
            {{category.label}}
          </span>
          <span data-test-legend-data class="{{if @labelsBelow 'block'}} font-bold">
            {{category.data}}
          </span>
        </div>
      {{/each}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartLegend;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        ChartLegend: typeof ChartLegend;
    }
}
