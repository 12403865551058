import { template as template_4d6baad1cea5438fbd22e06bc1a6f0ec } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { CleverUiHeader } from 'district-ui-client/components/clever-ui/header';
import type CleverIdRoute from 'district-ui-client/clever-id/route';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<CleverIdRoute>;
    };
}
export const CleverIdRouteTemplate: TOC<Signature> = template_4d6baad1cea5438fbd22e06bc1a6f0ec(`
  <CleverUiHeader @title={{t "clever.matchDistrictPageTitle"}} @subscriptionType={{@model.subscriptionType}} />
  <p class="text-center text-base">
    {{t "clever.matchDistrictPageIntroduction"}}
  </p>
  {{outlet}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(CleverIdRouteTemplate);
