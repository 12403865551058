import { template as template_1ecb91175b6d4da6a184d0b6610b1263 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { ReportingPageHeader } from 'district-ui-client/components/reporting/page-header';
import { ReportingScopeHeader } from 'district-ui-client/components/reporting/scope-header';
interface Signature {
    Element: HTMLDivElement;
}
export const ReportingDistrictHeader: TOC<Signature> = template_1ecb91175b6d4da6a184d0b6610b1263(`
  <ReportingPageHeader class="mb-8" />
  <ReportingScopeHeader />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ReportingDistrictHeader;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::DistrictHeader': typeof ReportingDistrictHeader;
    }
}
