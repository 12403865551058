import { template as template_92f73be0838e41c39d9cdcded08504ac } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { TrophyViewModel } from 'district-ui-client/components/charts/dashboard/awards-earned/trophies';
import { t } from 'ember-intl';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        trophy: TrophyViewModel;
    };
}
export const AwardsEarnedTrophy: TOC<Signature> = template_92f73be0838e41c39d9cdcded08504ac(`
  <div class="relative" ...attributes>
    <img src={{@trophy.src}} class="drop-shadow-[7px_7px_1px_#E0E0E0]" alt={{t "awards.trophy"}} />
    <div class="absolute left-1/2 top-[20%] -translate-x-1/2 transform text-xl font-bold leading-none text-black">
      {{@trophy.value}}
    </div>
    <div class="absolute left-1/2 top-[40%] -translate-x-1/2 transform font-bold leading-none text-white">
      {{@trophy.label}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
