import { template as template_cfe2676e09814782bf893c3322a98646 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { concat } from '@ember/helper';
import { get } from '@ember/helper';
import { Product } from 'district-ui-client/domain/product';
import { t } from 'ember-intl';
interface ProductPillSignature {
    Element: HTMLDivElement;
    Args: {
        product: Product;
    };
}
const background: Record<Product, string> = {
    [Product.RE]: 'bg-purply-pink-100',
    [Product.REX]: 'bg-purply-pink-100',
    [Product.FP]: 'bg-purply-pink-100',
    [Product.MS]: 'bg-oceany-blue-100',
    [Product.MSP]: 'bg-oceany-blue-100',
    [Product.WL]: 'bg-forest-green-100'
};
const text: Record<Product, string> = {
    [Product.RE]: 'text-purply-pink-350',
    [Product.REX]: 'text-purply-pink-350',
    [Product.FP]: 'text-purply-pink-350',
    [Product.MS]: 'text-oceany-blue-400',
    [Product.MSP]: 'text-oceany-blue-400',
    [Product.WL]: 'text-ms-green-400'
};
export const ProductPill: TOC<ProductPillSignature> = template_cfe2676e09814782bf893c3322a98646(`
  <div
    class="{{get background @product}}
      {{get text @product}}
      inline-block whitespace-nowrap rounded-full px-2.5 py-1.5 align-middle text-sm font-semibold uppercase"
    ...attributes
  >
    {{t (concat "products." @product)}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface PillSignature {
    Element: HTMLDivElement;
    Args: {
        disabled?: boolean;
    };
    Blocks: {
        default: [];
    };
}
export const Pill: TOC<PillSignature> = template_cfe2676e09814782bf893c3322a98646(`
  <div
    class="{{if @disabled 'text-neutral-250 bg-neutral-50' 'text-blue-350 bg-blue-100'}}
      inline-block cursor-auto whitespace-nowrap rounded-xl px-2 py-0.5 text-center text-sm font-normal"
    ...attributes
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
