import { template as template_295527f50678499290e29f85bb95c39c } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type Controller from '@ember/controller';
import { ErrorMessage } from 'district-ui-client/components/error-message';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        controller: Controller & {
            subscriptionType: SubscriptionType;
        };
    };
}
//  Will likely want a different error message for a 404 page at some point
export const Error404RouteTemplate: TOC<Signature> = template_295527f50678499290e29f85bb95c39c(`
  <ErrorMessage data-test-subscription-type-404 @subscriptionType={{@controller.subscriptionType}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(Error404RouteTemplate);
