import { template as template_5e33607e1de34b0b954b51894b6cb9c1 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { State } from 'reactiveweb/function';
import { NoDataMessage } from 'district-ui-client/components/no-data-message';
import { ErrorsUnexpected } from 'district-ui-client/components/errors/unexpected';
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave';
import { isObjectWithKey } from 'district-ui-client/utils/type-guards';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        reportStates: State<unknown>[];
    };
}
/**
 * The standards_set_id key being present (even if null) indicates a report is based on standards.
 */ function isStandardsReport(reportValue: unknown): reportValue is {
    meta: {
        standards_set_id: Nullable<string>;
    };
} {
    return (isObjectWithKey(reportValue, 'meta') && isObjectWithKey(reportValue.meta, 'standards_set_id') && typeof reportValue.meta.standards_set_id !== 'undefined');
}
export class NoReportValue extends Component<Signature> {
    get isLoading() {
        return this.args.reportStates.some((state)=>state.isPending);
    }
    get hasFailed() {
        return this.args.reportStates.some((state)=>state.isError || state.isRejected);
    }
    /**
   * A report expects standards, but they were not set when the report was generated.
   */ get missingStandards() {
        return this.args.reportStates.some((state)=>isStandardsReport(state.value) && state.value.meta.standards_set_id === null);
    }
    static{
        template_5e33607e1de34b0b954b51894b6cb9c1(`
    <div ...attributes>
      {{#if this.isLoading}}
        <FidgetSpinnerWaveComponent @centered={{true}} data-test-report-loading />
      {{else if this.hasFailed}}
        <ErrorsUnexpected data-test-report-error />
      {{else if this.missingStandards}}
        {{! There's a block here if you need it, but current designs show empty space for this }}
      {{else}}
        <NoDataMessage data-test-report-empty />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default NoReportValue;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::NoReportValue': typeof NoReportValue;
    }
}
