import { template as template_606b3ed695044d4fa6e1f4bbd85c6493 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { LinkTo } from '@ember/routing';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { array } from '@ember/helper';
import { t } from 'ember-intl';
interface Args {
    subscriptionType?: SubscriptionType;
}
interface Signature {
    Element: HTMLDivElement;
    Args: Args;
}
// Depending on which error page it lands on, this component may or may not have access to @subscriptionType or @error }}
export const ErrorMessage: TOC<Signature> = template_606b3ed695044d4fa6e1f4bbd85c6493(`
  <div ...attributes>
    {{#if @subscriptionType}}
      <LinkTo
        data-test-subscription-type-link
        @route="subscription-type"
        @models={{array @subscriptionType}}
        class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350"
      >
        <FaIcon @icon="chevron-left" @size="xs" />
        {{t "errorPages.link"}}
      </LinkTo>
    {{else}}
      <LinkTo
        data-test-landing-link
        @route="landing"
        class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350"
      >
        <FaIcon @icon="chevron-left" @size="xs" />
        {{t "errorPages.link"}}
      </LinkTo>
    {{/if}}

    <div class="h1">{{t "errorPages.title"}}</div>
    <p class="text-base">{{t "errorPages.body"}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ErrorMessage;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        ErrorMessage: typeof ErrorMessage;
    }
}
