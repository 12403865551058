import { template as template_f8960b7c438e40888186af7f09a0223e } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import LoadingSpinner from 'district-ui-client/components/loading-spinner';
import RouteTemplate from 'ember-route-template';
export const LoadingRouteTemplate: TOC<unknown> = template_f8960b7c438e40888186af7f09a0223e(`<LoadingSpinner />`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(LoadingRouteTemplate);
