import { template as template_b21e825c71f2472d90e0bf274dfbf32e } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { not } from 'ember-truth-helpers';
import { ChartsBooksReadByGenreTotals } from 'district-ui-client/components/charts/books-read/by-genre/totals';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import { t } from 'ember-intl';
import type ReBooksReadRoute from 'district-ui-client/reporting/ui-scope/re/books-read/route';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<ReBooksReadRoute>;
    };
}
export const ReBooksReadRouteTemplate: TOC<Signature> = template_b21e825c71f2472d90e0bf274dfbf32e(`
  <DetailedPageWrapper @empty={{not @model.booksReadByGenreTotals}}>
    {{#each @model.booksReadByGenreTotals.books_read_by_genre_totals as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsBooksReadByGenreTotals
          class="h-full"
          @data={{gradeData}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.booksReadByGenreTotals"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReBooksReadRouteTemplate);
