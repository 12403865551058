import { template as template_3d1dd0b60c9244bc973bce4694ad0934 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { on } from '@ember/modifier';
import { eq } from 'ember-truth-helpers';
import { eventValue } from 'district-ui-client/helpers/event-value';
import type ContentfulClient from 'district-ui-client/services/contentful-client';
import config from 'district-ui-client/config/environment';
export class ContentfulSelect extends Component<{
    Element: HTMLDivElement;
}> {
    @service
    contentfulClient: ContentfulClient;
    get showSelect() {
        return config.contentful.showSelector;
    }
    get selectedEnvironment() {
        return window.localStorage.getItem('district-ui-client.contentful.selected-environment');
    }
    get availableEnvironments() {
        return this.contentfulClient.availableEnvironments;
    }
    get usePreview() {
        return window.localStorage.getItem('district-ui-client.contentful.use-preview') === 'true';
    }
    setEnvironment(environmentId: string) {
        window.localStorage.setItem('district-ui-client.contentful.selected-environment', environmentId);
        window.location.replace('/');
    }
    setPreview(event: Event) {
        if (!(event.target instanceof HTMLInputElement)) return;
        const usePreview = String(event.target.checked);
        window.localStorage.setItem('district-ui-client.contentful.use-preview', usePreview);
        window.location.replace('/');
    }
    static{
        template_3d1dd0b60c9244bc973bce4694ad0934(`
    {{! template-lint-disable no-bare-strings }}
    {{#if this.showSelect}}
      <div ...attributes>
        Contentful:
        {{! template-lint-disable require-input-label }}
        <select {{on "input" (eventValue this.setEnvironment)}}>
          {{#each this.availableEnvironments as |env|}}
            <option value={{env}} selected={{eq this.selectedEnvironment env}}>{{env}}</option>
          {{/each}}
        </select>
        <span class="mx-1 border-l-2 border-black"></span>
        Preview:
        <input type="checkbox" checked={{this.usePreview}} {{on "input" this.setPreview}} />
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
