import { template as template_fddc7982c88a499bad8e79203f4da239 } from "@ember/template-compiler";
import { Alert } from 'district-ui-client/components/alert';
import { t } from 'ember-intl';
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
interface Signature {
    Args: {
        error: {
            code: unknown;
            status: unknown;
            jobId: unknown;
        };
    };
}
export class ImportPanelStudentUnexpectedError extends Component<Signature> {
    get errorDetails() {
        const errors = Object.entries(this.args.error);
        const errorsHtmlSafe = errors.map(([key, value])=>{
            if (typeof value === 'string' || typeof value === 'number') {
                return {
                    key,
                    value
                };
            }
            return null;
        }).filter(isPresent);
        return errorsHtmlSafe;
    }
    static{
        template_fddc7982c88a499bad8e79203f4da239(`
    <Alert @tone="critical" class="mt-7" data-test-unexpected-error>
      <span class="block font-bold">
        {{t "manage.studentCsv.errors.unhandled.explanation"}}
      </span>
      <div class="block p-5">
        <table class="rounded-sm border-black border-opacity-100 bg-transparent">
          <tbody>
            {{#each this.errorDetails as |errorDetail|}}
              <tr>
                <td class="py-0.5 pl-0.5 text-right">
                  <pre>{{errorDetail.key}}:</pre>
                </td>
                <td class="p-0.5">
                  <pre>{{errorDetail.value}}</pre>
                </td>
              </tr>{{/each}}
          </tbody>
        </table>
      </div>
      <p class="m-0">
        {{t "manage.studentCsv.errors.unhandled.retry"}}
        {{t "manage.studentCsv.errors.unhandled.support"}}
        <LinkTo
          @route="subscription-type.support"
          class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350"
        >{{t "manage.studentCsv.errors.unhandled.supportLink"}}</LinkTo>.
      </p>
    </Alert>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ImportPanelStudentUnexpectedError;
