import { template as template_111450f4ba05413887465906e3191cf2 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { reportingLegend } from 'district-ui-client/utils/giraffe/highcharts-config';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import colors from '@blakeelearning/blake-colours/colours';
import type { IntlService } from 'ember-intl';
import type { ReportingProductSlug } from 'district-ui-client/domain/product';
import { SeriesColumn } from 'district-ui-client/components/primitives/series-column/component';
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart';
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box';
import { eq } from 'ember-truth-helpers';
import type { SeriesColumnOptions } from 'highcharts';
/**
 * Internal Component called by awards-earned/certificates/totals and
 * awards-earned/individual. Renders the series-column chart for certificates
 * earned; Mathseeds variant.
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   summary: [
 *     { category: 'gold', count: 90 },
 *     { category: 'silver', count: 123 },
 *     { category: 'bronze', count: 250 },
 *   ],
 *   months: [
 *     {
 *       gold: 20,
 *       silver: 23,
 *       bronze: 125,
 *       month: '2016-07',
 *     },
 *     {
 *       gold: 70,
 *       silver: 100,
 *       bronze: 125,
 *       month: '2016-08',
 *     },
 *   ],
 * ```
 */ export interface AwardsEarnedCertificatesByMonthData {
    grade_position: number;
    summary: {
        category: string;
        count: number;
    }[];
    months: {
        gold: number;
        silver: number;
        bronze: number;
        month: string;
    }[];
}
interface Signature {
    Args: {
        data?: AwardsEarnedCertificatesByMonthData;
        product: ReportingProductSlug;
        yAxisTitle?: string;
        grade?: string;
        title?: string;
    };
    Element: HTMLDivElement;
}
export class ChartsAwardsEarnedCertificatesByMonth extends Component<Signature> {
    @service
    intl: IntlService;
    /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total gold, silver and bronze counts.
   */ get summaryStats() {
        const summaryData = this.args.data?.summary ?? [];
        const gold = summaryData.find((d)=>d.category === 'gold')?.count ?? 0;
        const silver = summaryData.find((d)=>d.category === 'silver')?.count ?? 0;
        const bronze = summaryData.find((d)=>d.category === 'bronze')?.count ?? 0;
        const total = summaryData.reduce((sum, d)=>sum + d.count, 0);
        return [
            {
                label: 'Gold',
                value: formatNumber(gold, 0)
            },
            {
                label: 'Silver',
                value: formatNumber(silver, 0)
            },
            {
                label: 'Bronze',
                value: formatNumber(bronze, 0)
            },
            {
                label: 'Total',
                value: formatNumber(total, 0)
            }
        ];
    }
    /**
   * The student data transformed to be rendered by highcharts.
   * Made up of three series of data, one for each certificate colour.
   */ get chartData(): SeriesColumnOptions[] {
        return [
            {
                name: this.intl.t(`reporting.charts.awardsEarned.certificates.byMonth.goldLabel.${this.args.product}`),
                data: this.args.data?.months?.map((m)=>m.gold) ?? [],
                color: colors.medalGold,
                legendIndex: 3,
                type: 'column'
            },
            {
                name: this.intl.t(`reporting.charts.awardsEarned.certificates.byMonth.silverLabel.${this.args.product}`),
                data: this.args.data?.months?.map((m)=>m.silver) ?? [],
                color: colors.medalSilver,
                legendIndex: 2,
                type: 'column'
            },
            {
                name: this.intl.t(`reporting.charts.awardsEarned.certificates.byMonth.bronzeLabel.${this.args.product}`),
                data: this.args.data?.months?.map((m)=>m.bronze) ?? [],
                color: colors.medalBronze,
                legendIndex: 1,
                type: 'column'
            }
        ];
    }
    /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */ get categories() {
        return this.args.data?.months?.map((m)=>convertDateFormat(m.month)) ?? [];
    }
    get legend() {
        return reportingLegend('left', 'top', 30, -15);
    }
    static{
        template_111450f4ba05413887465906e3191cf2(`
    <div class="relative" ...attributes>
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{if (eq @product "maths") SummaryBoxColorScheme.DarkGreen SummaryBoxColorScheme.LightBlue}}
          />
        </:left>
        <:right>
          <SeriesColumn
            @data={{this.chartData}}
            @categories={{this.categories}}
            @stackingStyle="normal"
            @legend={{this.legend}}
            @yAxisLabel={{@yAxisTitle}}
            @title={{@title}}
            @chartMargin={{Array 60 10 30 55}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsAwardsEarnedCertificatesByMonth;
