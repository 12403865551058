import { template as template_c2164b76126d44f39368c8ea65a40afa } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { and, eq } from 'ember-truth-helpers';
import { formatNumber, t, type IntlService } from 'ember-intl';
import { type Task } from 'ember-concurrency';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { Panel, PanelHeader, PanelSubHeader, PanelTitle, PanelBody } from 'district-ui-client/components/section';
import { joinList } from 'district-ui-client/helpers/join-list';
import { ThemedButton } from 'district-ui-client/components/themed-button';
import { Tooltip, TooltipInfo } from 'district-ui-client/components/tooltip';
import { formatValue } from 'district-ui-client/utils/format-value';
import { TableContainer, Table, TBody, TH, TD, TFootPageSelector } from 'district-ui-client/components/table';
import { includes } from 'district-ui-client/helpers/includes';
import type ReportingService from 'district-ui-client/services/reporting';
import { type BaseReport, type BaseReportMetaPaging, type BaseReportMetaStandardsSet } from 'district-ui-client/services/reporting';
import { service } from '@ember/service';
import { Product } from 'district-ui-client/domain/product';
function avgScoreColor(score: Nullable<number>): string {
    if (typeof score !== 'number') return '';
    switch(true){
        case score <= 49:
            return 'text-red-350';
        case score <= 79:
            return 'text-yellow-400';
        case score <= 100:
            return 'text-green-350';
        default:
            return '';
    }
}
interface Signature {
    Args: {
        reportData: StandardsPerformanceResultsReportData;
        isReloading?: boolean;
        exportCsv?: Task<unknown, unknown[]>;
        isSingleSchool?: boolean;
    };
}
export interface StandardsPerformanceResultsReportData extends BaseReport {
    aggregate: {
        total_schools: number;
        total_students: number;
    };
    results: {
        content_level: string;
        lesson: number;
        lesson_concepts: string[];
        standards: string[];
        aggregate: PerformanceItem;
        schools: ({
            school_id: string;
            school_name: string;
        } & PerformanceItem)[];
    }[];
    meta: BaseReportMetaPaging & BaseReportMetaStandardsSet;
}
interface PerformanceItem {
    total_students: number;
    average_score: Nullable<number>;
    student_count_distribution: Nullable<DistributionBands>;
}
type DistributionBands = [{
        band: '0-49';
        total_students: number;
        percentage: number;
    }, {
        band: '50-79';
        total_students: number;
        percentage: number;
    }, {
        band: '80-100';
        total_students: number;
        percentage: number;
    }];
export class StandardsPerformanceResults extends Component<Signature> {
    @service
    intl: IntlService;
    @service
    reporting: ReportingService;
    @tracked
    openedRows: number[] = [];
    get allOpened() {
        return this.openedRows.length === this.args.reportData.results.length;
    }
    toggleRow = (rowId: number)=>{
        if (this.openedRows.includes(rowId)) {
            this.openedRows = this.openedRows.filter((id)=>rowId !== id);
        } else {
            this.openedRows = [
                ...this.openedRows,
                rowId
            ];
        }
    };
    toggleAll = ()=>{
        if (this.allOpened) {
            this.openedRows = [];
        } else {
            this.openedRows = this.args.reportData.results.map((resultItem)=>resultItem.lesson) ?? [];
        }
    };
    // this needs to be based on scope selection, rather than number of schools in reporting results data
    get showExpander() {
        return !this.args.isSingleSchool;
    }
    get isFastPhonics() {
        return this.reporting.product === Product.FP;
    }
    get legendTooltipDescription() {
        if (this.isFastPhonics) return this.intl.t('reporting.standardsPerformance.legendTooltipPeaks');
        return this.intl.t('reporting.standardsPerformance.legendTooltip');
    }
    get lessonFocusHeader() {
        if (this.isFastPhonics) {
            return this.intl.t('reporting.standardsPerformance.headers.lessonFocusPeaks', {
                htmlSafe: true
            });
        }
        return this.intl.t('reporting.standardsPerformance.headers.lessonFocus');
    }
    static{
        template_c2164b76126d44f39368c8ea65a40afa(`
    <Panel data-test-standards-performance-results-panel>
      <PanelHeader>
        <div class="flex items-start justify-between gap-4">
          <div class="space-y-2">
            <PanelTitle>{{t "reporting.standardsPerformance.standardsResults"}}</PanelTitle>
            <div class="text-sm">
              {{t
                "reporting.showingDataForStudentsFromSchools"
                studentCount=(formatNumber @reportData.aggregate.total_students)
                schoolCount=(formatNumber @reportData.aggregate.total_schools)
              }}
            </div>
          </div>
          {{#if @exportCsv}}
            <ThemedButton
              class="print:hidden"
              @style="theme"
              @disabled={{@exportCsv.isRunning}}
              {{on "click" @exportCsv.perform}}
            >
              {{t "exportCsv"}}
            </ThemedButton>
          {{/if}}
        </div>
      </PanelHeader>
      <PanelSubHeader>
        <div class="flex gap-5 text-sm font-medium">
          <div class="space-x-2">
            {{! template-lint-disable no-bare-strings }}
            <FaIcon @icon="circle" class="text-red-200" /><span class="text-neutral-300">0-49%</span>
          </div>
          <div class="space-x-2">
            {{! template-lint-disable no-bare-strings }}
            <FaIcon @icon="circle" class="text-yellow-200" /><span class="text-neutral-300">50-79%</span>
          </div>
          <div class="space-x-2">
            {{! template-lint-disable no-bare-strings }}
            <FaIcon @icon="circle" class="text-green-200" /><span class="text-neutral-300">80-100%</span>
          </div>
          <TooltipInfo @text={{this.legendTooltipDescription}} />
        </div>
      </PanelSubHeader>
      <PanelBody @tight={{true}}>
        <TableContainer>
          <Table @minWidth="min-w-[52rem]">
            <thead>
              <tr class="bg-neutral-50">
                {{#if this.showExpander}}
                  <TH class="w-16 text-center">
                    <button data-test-expand-all type="button" class="text-base" {{on "click" this.toggleAll}}>
                      {{#if this.allOpened}}
                        <FaIcon @icon="angle-up" class="text-blue-325 h-[20px] w-[20px]" />
                      {{else}}
                        <FaIcon @icon="angle-down" class="h-[20px] w-[20px]" />
                      {{/if}}
                    </button>
                  </TH>
                {{/if}}
                {{! avoids applying divide classes to the expander cell }}
                <div class="contents divide-x-2 divide-white align-middle">
                  <TH @sortKey="content_level" class="w-24">
                    {{t "reporting.standardsPerformance.headers.contentLevel" htmlSafe=true}}
                  </TH>
                  <TH @sortKey="lesson">{{this.lessonFocusHeader}}</TH>
                  <TH>{{t "reporting.standardsPerformance.headers.standards"}}</TH>
                  <TH @sortKey="total_students" class="w-32">
                    {{t "reporting.standardsPerformance.headers.numberOfStudents" htmlSafe=true}}
                  </TH>
                  <TH>
                    {{t "reporting.standardsPerformance.headers.studentCountDistribution" htmlSafe=true}}
                    <TooltipInfo @text={{t "reporting.standardsPerformance.headers.studentCountDistributionTooltip"}} />
                  </TH>
                  <TH @sortKey="average_score" class="w-24">
                    {{t "reporting.standardsPerformance.headers.averageScore" htmlSafe=true}}
                  </TH>
                </div>
              </tr>
            </thead>
            <TBody class={{if @isReloading "opacity-50"}}>
              {{#each @reportData.results as |resultItem|}}
                {{#let (and this.showExpander (includes this.openedRows resultItem.lesson)) as |isOpenedRow|}}
                  <tr class={{if isOpenedRow "bg-neutral-50 font-semibold"}}>
                    {{#if this.showExpander}}
                      <TD class="text-center">
                        <button
                          data-test-expand
                          type="button"
                          class="text-base"
                          {{on "click" (fn this.toggleRow resultItem.lesson)}}
                        >
                          {{#if isOpenedRow}}
                            <FaIcon @icon="angle-up" class="text-blue-325 h-[20px] w-[20px]" />
                          {{else}}
                            <FaIcon @icon="angle-down" class="h-[20px] w-[20px]" />
                          {{/if}}
                        </button>
                      </TD>
                    {{/if}}
                    <TD>{{resultItem.content_level}}</TD>
                    <TD>
                      {{#each resultItem.lesson_concepts as |concept index|}}
                        <div class={{if (eq index 0) "font-semibold"}}>{{concept}}</div>
                      {{/each}}
                    </TD>
                    <TD>{{joinList resultItem.standards ", "}}</TD>
                    <TD class="font-semibold">
                      <span class="text-lg"><FaIcon @icon="users" class="mr-2" /></span>
                      {{formatNumber resultItem.aggregate.total_students}}
                    </TD>
                    <TD>
                      <StudentCountDistribution @distribution={{resultItem.aggregate.student_count_distribution}} />
                    </TD>
                    <TD class="font-semibold" @textColor={{avgScoreColor resultItem.aggregate.average_score}}>
                      {{formatValue resultItem.aggregate.average_score append="%"}}
                    </TD>
                  </tr>
                  {{#if isOpenedRow}}
                    {{#each resultItem.schools as |schoolDistributionItem|}}
                      <tr class="bg-neutral-50 font-semibold">
                        <TD />
                        <TD colspan="3">{{schoolDistributionItem.school_name}}</TD>
                        <TD>
                          <span class="text-lg"><FaIcon @icon="users" class="mr-2" /></span>
                          {{formatNumber schoolDistributionItem.total_students}}
                        </TD>
                        <TD>
                          <StudentCountDistribution
                            @distribution={{schoolDistributionItem.student_count_distribution}}
                          />
                        </TD>
                        <TD @textColor={{avgScoreColor schoolDistributionItem.average_score}}>
                          {{formatValue schoolDistributionItem.average_score append="%"}}
                        </TD>
                      </tr>
                    {{/each}}
                  {{/if}}
                {{/let}}
              {{/each}}
            </TBody>
            <TFootPageSelector class="border-neutral-75 border-t" @pageCount={{@reportData.meta.total_pages}} />
          </Table>
        </TableContainer>
      </PanelBody>
    </Panel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
function basisPercentage(perc: number) {
    return htmlSafe(`flex-basis: ${perc}%`);
}
interface StudentCountDistributionSignature {
    Element: HTMLDivElement;
    Args: {
        distribution?: Nullable<DistributionBands>;
    };
}
const StudentCountDistribution: TOC<StudentCountDistributionSignature> = template_c2164b76126d44f39368c8ea65a40afa(`
  <div class="group flex gap-0.5 overflow-hidden rounded-full border-2 border-white bg-white" ...attributes>
    {{#each @distribution as |distributionItem|}}
      <div
        {{! when a group item (and thus the group) are hovered: fade the unhovered siblings in the group }}
        class="px-1 py-0.5 text-center text-xs font-medium hover:!opacity-100 group-hover:opacity-50
          {{if (eq distributionItem.band '0-49') 'bg-red-100'}}
          {{if (eq distributionItem.band '50-79') 'bg-yellow-150'}}
          {{if (eq distributionItem.band '80-100') 'bg-green-150'}}"
        style={{basisPercentage distributionItem.percentage}}
      >
        {{formatNumber distributionItem.total_students}}
        <Tooltip @text="{{formatNumber distributionItem.percentage}}%" />
      </div>
    {{/each}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
