import { template as template_dd19ee0c39f8442da9cc1f34148c7099 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator';
export const LoadingSpinner: TOC<unknown> = template_dd19ee0c39f8442da9cc1f34148c7099(`
  <FidgetLoadingIndicatorComponent @show={{true}} @overlay={{false}} @loadingText="Loading..." @centered={{true}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LoadingSpinner;
