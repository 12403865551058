import { template as template_ed2a9a473b9942a8936782ae9b575a9d } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import colors from '@blakeelearning/blake-colours/colours';
import type { IntlService } from 'ember-intl';
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart';
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box';
import { SeriesColumn } from 'district-ui-client/components/primitives/series-column/component';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import type { SeriesColumnOptions } from 'highcharts';
/**
 * Puts together all the pieces for the stories written totals chart
 */ export interface StoriesWrittenTotalsData {
    grade_position: number;
    summary: {
        category: string;
        count: number;
    }[];
    months: {
        count: Nullable<number>;
        month: string;
    }[];
}
interface Signature {
    Args: {
        data?: StoriesWrittenTotalsData;
        grade?: string;
        title?: string;
        tooltip?: string;
    };
    Element: HTMLDivElement;
}
export class ChartsStoriesWrittenTotals extends Component<Signature> {
    @service
    intl: IntlService;
    /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total stories written and student counts, and average stories per student.
   */ get summaryStats() {
        const summaryData = this.args.data?.summary ?? [];
        const total = summaryData.find((d)=>d.category === 'total_count')?.count ?? 0;
        const studentCount = summaryData.find((d)=>d.category === 'student_count')?.count ?? 0;
        const average = basicAverage(total, studentCount);
        return [
            {
                label: this.intl.t('reporting.summaryTitles.storiesWrittenTotals.total'),
                value: formatNumber(total, 1)
            },
            {
                label: this.intl.t('reporting.summaryTitles.storiesWrittenTotals.students'),
                value: formatNumber(studentCount, 1)
            },
            {
                label: this.intl.t('reporting.summaryTitles.storiesWrittenTotals.average'),
                value: formatNumber(average, 1)
            }
        ];
    }
    /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */ get columns(): SeriesColumnOptions[] {
        const monthData = this.args.data?.months ?? [];
        const color = colors.oceanyBlue300;
        return [
            {
                name: 'count',
                data: monthData.map(({ count })=>count),
                color,
                type: 'column'
            }
        ];
    }
    get months() {
        return this.args.data?.months?.map((m)=>convertDateFormat(m.month)) ?? [];
    }
    static{
        template_ed2a9a473b9942a8936782ae9b575a9d(`
    <div data-test-stories-written-totals class="relative" ...attributes>
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{SummaryBoxColorScheme.DarkBlue}}
          />
        </:left>
        <:right>
          <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
          <SeriesColumn
            @title={{@title}}
            @data={{this.columns}}
            @categories={{this.months}}
            @chartSpacing={{Array 10 10 10 10}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsStoriesWrittenTotals;
