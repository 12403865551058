import { template as template_904b3843491f48c4a7328c2c68d42479 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { or } from 'ember-truth-helpers';
interface Signature {
    Element: HTMLButtonElement;
    Args: {
        buttonType?: string;
        corners?: string;
        disabled?: boolean;
    };
    Blocks: {
        default: [];
    };
}
/**
 * Usage:
    <UiButton
      class="ui-btn-small"
      @disabled={{isDisabled}}
      @buttonType="submit"
      @corners="rounded rounded-l-none"
      {{on "click" this.myAction}}
    >
      abc
    </UiButton>
  Can use either @disabled or disabled
 */ export const UiButton: TOC<Signature> = template_904b3843491f48c4a7328c2c68d42479(`
  <button
    class="__ui-button__fd7ee {{or @corners 'rounded'}}"
    type={{or @buttonType "button"}}
    disabled={{@disabled}}
    ...attributes
  >
    {{yield}}
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiButton;
