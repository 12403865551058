import { template as template_9f44ee6c832d489eabc6d498b2adadcc } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
}
export const NotificationDot: TOC<Signature> = template_9f44ee6c832d489eabc6d498b2adadcc(`
  <div class="inline-block h-1.5 w-1.5 shrink-0 grow-0 rounded-full bg-pink-300" ...attributes></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
