import { template as template_4b7c74fb3904427ea3831555ccd39c1a } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import { eventValue } from 'district-ui-client/helpers/event-value';
import { InputText } from 'district-ui-client/components/input-text';
interface Signature {
    Element: HTMLElement;
    Args: {
        value?: string;
        updateValue(value: string): void;
    };
}
export const InputSearch: TOC<Signature> = template_4b7c74fb3904427ea3831555ccd39c1a(`
  <InputText
    data-test-input-search
    type="search"
    role="searchbox"
    value={{@value}}
    placeholder={{t "search"}}
    aria-label={{t "search"}}
    @icon="search"
    @iconEnd={{true}}
    {{on "input" (eventValue @updateValue)}}
    ...attributes
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputSearch;
